/**
 * Adds a Confused.CookieManagerV2 class to the Confused namespace.
 * Implemented as IIFE.
 **/
(function (w, d) {
	'use strict';

	w.Confused = w.Confused || {};

	/**
	 * Helper for working with cookies. 95% of this code was written by Mozilla.
	 * https://developer.mozilla.org/en-US/docs/Web/API/d.cookie
	 *
	 * @Constructor
	 */
	w.Confused.CookieManagerV2 = function () {};

	/**
	 * Gets a cookie item. e.g.
	 * cookieManagerV2.getItem(name)
	 *
	 * @param {string} sKey. The name of the cookie.
	 */
	w.Confused.CookieManagerV2.prototype.getItem = function (sKey) {
		return (
			decodeURIComponent(
				d.cookie.replace(
					new RegExp(
						`(?:(?:^|.*;)\\s*${encodeURIComponent(sKey).replace(
							/[\-\.\+\*]/g,
							'\\$&'
						)}\\s*\\=\\s*([^;]*).*$)|^.*$`
					),
					'$1'
				)
			) || null
		);
	};

	/**
	 * Sets a cookie item. e.g.
	 * cookieManagerV2.setItem("test0", "Hello world!");
	 * cookieManagerV2.setItem("test1", "Unicode test: \u00E0\u00E8\u00EC\u00F2\u00F9", Infinity);
	 * cookieManagerV2.setItem("test2", "Hello world!", new Date(2020, 5, 12));
	 * cookieManagerV2.setItem("test3", "Hello world!", new Date(2027, 2, 3), "/blog");
	 * cookieManagerV2.setItem("test4", "Hello world!", "Sun, 06 Nov 2022 21:43:15 GMT");
	 * cookieManagerV2.setItem("test5", "Hello world!", "Tue, 06 Dec 2022 13:11:07 GMT", "/home");
	 * cookieManagerV2.setItem("test6", "Hello world!", 150);
	 * cookieManagerV2.setItem("test7", "Hello world!", 245, "/content");
	 * cookieManagerV2.setItem("test8", "Hello world!", null, null, "example.com");
	 * cookieManagerV2.setItem("test9", "Hello world!", null, null, null, true);
	 * cookieManagerV2.setItem("test1;=", "Safe character test;=", Infinity);
	 *
	 * @param {string} sKey. The name of the cookie.
	 * @param {string} sValue. The value of the cookie.
	 * @param {variant} vEnd. The expiry date of the cookie.
	 * @param {string} sPath. The path of the cookie.
	 * @param {string} sDomain. The domain of the cookie.
	 * @param {bool} bSecure. Specifies if the cookie is secure or not.
	 */
	w.Confused.CookieManagerV2.prototype.setItem = function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
		if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
			return false;
		}

		let sExpires = '';

		if (vEnd) {
			switch (vEnd.constructor) {
				case Number:
					sExpires = vEnd === Infinity ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT' : `; max-age=${vEnd}`;
					break;

				case String:
					sExpires = `; expires=${vEnd}`;
					break;

				case Date:
					sExpires = `; expires=${vEnd.toUTCString()}`;
					break;
			}
		}

		d.cookie = `${encodeURIComponent(sKey)}=${encodeURIComponent(sValue)}${sExpires}${
			sDomain ? `; domain=${sDomain}` : ''
		}${sPath ? `; path=${sPath}` : ''}${bSecure ? '; secure' : ''}`;

		return true;
	};

	/**
	 * Removes a cookie item. e.g.
	 * cookieManagerV2.removeItem(name, path, domain)
	 *
	 * @param {string} sKey. The name of the cookie.
	 * @param {string} sPath. The path of the cookie: eg, "/" : defaults to path of current d.
	 * @param {string} sDomain. The domain of the cookie - eg, confused.com, .confused.com (all subdomains), www.confused.com
	 */
	w.Confused.CookieManagerV2.prototype.removeItem = function (sKey, sPath, sDomain) {
		if (!sKey || !this.hasItem(sKey)) {
			return false;
		}

		d.cookie = `${encodeURIComponent(sKey)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT${
			sDomain ? `; domain=${sDomain}` : ''
		}${sPath ? `; path=${sPath}` : ''}`;

		return true;
	};

	/**
	 * Tests if a cookie item exists. e.g.
	 * cookieManagerV2.hasItem(name)
	 *
	 * @param {string} sKey. The name of the cookie.
	 */
	w.Confused.CookieManagerV2.prototype.hasItem = function (sKey) {
		// eslint-disable-next-line
		return new RegExp('(?:^|;\\s*)' + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=').test(
			d.cookie
		);
	};

	/**
	 * Returns the keys for all cookie items. e.g.
	 * cookieManager.keys(name)
	 */
	w.Confused.CookieManagerV2.prototype.keys = function () {
		const aKeys = d.cookie

			.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, '')

			.split(/\s*(?:\=[^;]*)?;\s*/);

		for (let nIdx = 0; nIdx < aKeys.length; nIdx++) {
			aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]);
		}

		return aKeys;
	};
})(window, document);
