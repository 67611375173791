(function (w, d) {
	w.Confused = w.Confused || {};
	w.Confused.CookieModalFocus = function () {};

	w.Confused.CookieModalFocus.init = function () {
		const cookieWall = d.querySelector('.cnf-cookies_cookie-wall');
		let cookieModalLink;
		let cookieModalBtn1;
		let cookieModalBtn2;
		let cookieWallDetails;

		if (cookieWall) {
			cookieModalLink = cookieWall.querySelector('.cnf-cookies_cookie-policy-link');
			cookieModalBtn1 = cookieWall.querySelector('#button-save-all');
			cookieModalBtn2 = cookieWall.querySelector('.cnf-cookies_save-cookie-prefs');
			cookieWallDetails = cookieWall.querySelector('details');
		}

		function setFocus(element) {
			if (element) {
				element.focus();
			}
		}

		function onTabPress(event) {
			if (!cookieWall.classList.contains('cnf-cookies_open')) {
				return;
			}

			const activeElem = event.target;

			if (event.key === 'Tab') {
				if (!event.shiftKey) {
					if (activeElem === cookieModalBtn1 || activeElem === cookieModalBtn2) {
						event.preventDefault();
						setFocus(cookieModalLink);
					}
				} else if (activeElem === cookieModalLink) {
					event.preventDefault();
					setFocus(cookieWallDetails.hasAttribute('open') ? cookieModalBtn2 : cookieModalBtn1);
				}
			}
		}

		if (!cookieModalLink || !cookieWall.classList.contains('cnf-cookies_open')) {
			return;
		}
		setFocus(cookieModalLink);

		d.addEventListener('keydown', event => onTabPress(event));
	};
})(window, document);
