/*
 * Adds a CookiePolicyWidget class to the Confused namespace.
 * Implemented as IIFE so we can pass in references to the global window and document objects.
 * Note: Depends on _partials/Common/v2.0/CookieManager.js being setup at Confused.CookieManagerV2 and _core/mi/v1.1/miListener.js (or above) being supported server-side.
 * Adds/ removes '.open' to '.cookie-wall depending on the presence of a CookieConsent cookie, logging the interaction as an MI event and in a cookie
 * Triggers the function triggerGTM() which wraps around the GTM script on page to stop it firing and dropping cookies if CookieConsent cookie is not present
 * Cookie consent cookie name is retrieved from dataLAyer on page and defaults to CookieConsent is dataLayer doesn't have the entry
 */

(function (w) {
	'use strict';

	w.Confused = w.Confused || {};
	w.Confused.CookieManagerV2 = w.Confused.CookieManagerV2 || {};

	w.Confused.CookiePolicyWidget = (function (cookieManager) {
		// GET COOKIE & GTM ID NAME FROM DATALAYER
		const gtmContainerID = window.dataLayer[0].GTMContainer;
		const cookieName = window.dataLayer[0].ConsentCookieName;
		let parent = location.hostname;
		const domainRegex =
			/^([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])(\.([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]{0,61}[a-zA-Z0-9]))*$/i;
		const miListener = CONFUSEDCORE.MI.MiListener;
		let cookiePopupShown = false;

		if (domainRegex.test(parent)) {
			const parts = parent.split('.');
			parts.shift();
			parent = parts.join('.');
		}

		/* Show widget */
		function showPopup() {
			if (document.querySelector('.cnf-cookies_cookie-wall')) {
				document.querySelector('.cnf-cookies_cookie-wall').classList.add('cnf-cookies_open');
				cookiePopupShown = true;
				//miListener.postEvent('CookiePopupShown');
				window.Confused.CookieModalFocus.init();
			}
		}

		// VALIDATE ON BUTTON CLICK THAT THE A CHOICE HAS BEEN MADE
		function validateCookieSelection(btn, event) {
			if (btn.closest('.cnf-cookies_field-group') !== null) {
				const questionBlock = btn.closest('.cnf-cookies_field-group');

				if (
					document.querySelectorAll('.cnf-cookies_field-group .cnf-cookies_radio-input:checked').length === 0
				) {
					event.preventDefault();
					questionBlock.classList.add('cnf-cookies_error');
				} else {
					const selectedRadio = document.querySelector(
						'.cnf-cookies_field-group .cnf-cookies_radio-input:checked'
					).value;
					// IF WE ARE VALIDATING THE COOKIE WALL POPUP
					if (btn.classList.contains('cnf-cookies_cookie-wall__button')) {
						if (selectedRadio === 'yes') {
							hidePopup('true', 'CookiePopupDismissed:ConsentGranted');
						}

						if (selectedRadio === 'no') {
							hidePopup('false', 'CookiePopupDismissed:ConsentDeclined');
						}
					}

					// IF WE ARE VALIDATING THE COOKIE POLICY PAGE
					if (btn.classList.contains('cnf-cookies_cookie-choice__button')) {
						// SHOW SUCCESS MESSAGE
						document.querySelector('.cnf-cookies_save-changess-success').classList.remove('display-none');
						// DISABLE BUTTON AS ACTION COMPLETE
						btn.classList.add('cnf-cookies_disabled');

						setTimeout(function () {
							if (selectedRadio === 'yes') {
								updateCookieValue('true');
							}

							if (selectedRadio === 'no') {
								updateCookieValue('false');
							}

							insertGTMScript();
						}, 0);

						// HIDE SUCCESS MESSAGE AFTER 5 SECONDS
						setTimeout(function () {
							document.querySelector('.cnf-cookies_save-changess-success').classList.add('display-none');
						}, 5000);
					}
				}
				// IF COOKIE WALL POPUP ACCEPT ALL BUTTON IS PRESSED
			} else {
				if (document.querySelector('.cnf-cookies_cookie-wall')) {
					hidePopup('true', 'CookiePopupDismissed:ConsentGranted');
				}
			}
		}

		// REMOVES ERROR CLASS ON FIELDSET HIDING ERROR MESSAGE
		function removeErrorClass() {
			document.querySelector('.cnf-cookies_field-group').classList.remove('cnf-cookies_error');
		}

		// CLOSES MODAL
		function closeCookieModal() {
			document.querySelector('.cnf-cookies_cookie-wall').classList.remove('cnf-cookies_open');
		}

		/* Hide widget */
		function hidePopup(cookieValue, eventName) {
			closeCookieModal();
			updateCookieChoiceRadios();

			setTimeout(function () {
				cookieManager.setItem(cookieName, cookieValue, 2147483647, '/', parent);
				const cookieEvent = new CustomEvent('CookieConsentSet', { detail: cookieValue });
				document.dispatchEvent(cookieEvent);
				// INSERT THE GOOGLE TAG MANAGER SCRIPTS TO THE PAGE
				insertGTMScript();
				miListener.postEvent(eventName);
			}, 0);
		}

		// SET THE VALUE OF THE EDIT COOKIE PREFERENCES RADIOS ON COOKIE POLICY PAGE
		// BASED ON COOKIE VALUE SELECTED IN POPUP
		function updateCookieChoiceRadios() {
			if (cookieManager.hasItem(cookieName)) {
				if (document.querySelector('.cnf-cookies_cookie-choice__options')) {
					if (cookieManager.getItem(cookieName) === 'true') {
						document.querySelector('#cookie-choice__input--yes').checked = true;
					} else {
						document.querySelector('#cookie-choice__input--no').checked = true;
					}
					document.querySelector('.cnf-cookies_cookie-choice__button').classList.add('cnf-cookies_disabled');
				} else {
					return;
				}
			}
		}

		// UPDATE THE VALUE OF THE COOKIE FROM THE CHOICE MADE ON COOKIE POLICY PAGE
		function updateCookieValue(cookieValue) {
			cookieManager.setItem(cookieName, cookieValue, 2147483647, '/', parent);
		}

		//
		function insertGTMScript() {
			const s = document.createElement('script');
			s.type = 'text/javascript';
			s.async = 'true';
			// CHANGEING THE URL FROM GOOGLE TO OUR SERVERSIDE TAG
			/*s.src = 'https://www.googletagmanager.com/gtm.js?id=' + gtmContainerID;*/
			s.src = `https://sst.confused.com/push?node=${gtmContainerID}`;
			const x = document.getElementsByTagName('script')[0];
			x.parentNode.insertBefore(s, x);
		}

		function run() {
			// REGISTER GTM WITH DATALAYER ON LOAD
			// IDEA FOR PROCESS WAS FROM HERE:
			// https://stackoverflow.com/questions/60173853/how-to-set-the-google-analytics-cookie-only-after-another-consent-cookie-is-set/60223798#60223798

			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

			if (cookieManager.hasItem(cookieName)) {
				// INSERT THE GOOGLE TAG MANAGER SCRIPTS TO THE PAGE
				insertGTMScript();

				// IF WE ARE ON COOKIE POLICY PAGE AND COOKIE EXISTS WE NEED TO SET THE RADIOS
				// TO THE VALUE OF THE COOKIE
				updateCookieChoiceRadios();
			} else {
				showPopup();
			}

			// SETUP EVENT HANDLERS ON RADIOS & BUTTONS IN COOKIE WALL MODAL
			const buttons = document.querySelectorAll('.cnf-cookies_save-cookie-prefs');
			const inputs = document.querySelectorAll('.cnf-cookies_cookie-option-holder .cnf-cookies_radio-input');

			Array.prototype.forEach.call(buttons, function (button) {
				//buttons.forEach(function (button) {
				button.addEventListener('click', function (event) {
					const btn = event.target;
					validateCookieSelection(btn, event);
				});
			});

			Array.prototype.forEach.call(inputs, function (input) {
				//inputs.forEach(function(input) {
				input.addEventListener('change', function (e) {
					removeErrorClass();
					const radio = e.target;
					if (
						radio.closest('.cnf-cookies_cookie-choice__input-wrapper') !== null &&
						cookieManager.hasItem(cookieName)
					) {
						document.querySelectorAll('.cnf-cookies_cookie-choice__button').forEach(function (button) {
							button.classList.toggle('cnf-cookies_disabled');
						});
					}
				});
			});

			return;
		}

		function wasCookiePopupShown() {
			return cookiePopupShown;
		}

		return {
			run: run,
			wasCookiePopupShown: wasCookiePopupShown,
		};
	})(new w.Confused.CookieManagerV2());
})(window);
